
import { computed, ComputedRef, defineComponent } from 'vue';
import CLoginHeader from '@/views/login/components/login-header/login-header.component.vue';
import CSetPasswordForm from './components/set-password-form.component.vue'
import { useDeviceSize } from '@/hooks/use-page-size';
import CLoginFooterCookie from '@/views/login/components/login-footer-cookie/login-footer-cookie.component.vue';
import { useGlobals } from '@/hooks/use-globals';

export default defineComponent({
  name: 'VLogin',
  components: {
    CLoginFooterCookie,
    CLoginHeader,
    CSetPasswordForm,
  },
  setup() {
    const { isMobile } = useDeviceSize();
    const { store } = useGlobals();

    const isCookiePolicyAccepted: ComputedRef<boolean> = computed(() => store.state.views?.vLogin?.cookieAccepted || document.cookie.includes('cookiePolicy='));

    return {
      isMobile,
      isCookiePolicyAccepted,
    }
  }
});
