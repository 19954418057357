
import { defineComponent } from 'vue';
import CQualPro            from '@/components/common/logo-qualpro/logo-qualpro.component.vue';

export default defineComponent({
  name: 'CLoginHeader',
  components: {
    CQualPro,
  },
});
