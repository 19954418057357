
import { defineComponent } from 'vue';
import { useTranslations } from '@/hooks/use-translations';
import { useSetPasswordForm } from '@/views/setPassword/hooks/use-set-password-form';
import cButton from '@/components/common/button/button.component.vue';
import iconLock from '@/components/icons/icon-lock.component.vue';
import CLogo from '@/components/common/logo/logo.component.vue';
import { useGlobals } from '@/hooks/use-globals';

export default defineComponent({
  name: 'CLoginForm',
  components: {
    CLogo,
    iconLock,
    cButton,
  },
  setup() {
    const { router } = useGlobals();
    const {
      setToken,
      onSubmit,
      resetForm,
      disabledButton,
    } = useSetPasswordForm();

    const token = router.currentRoute.value.query.token as string;
    setToken(token ? token : '');

    return {
      onSubmit,
      resetForm,
      disabledButton,
      useTranslations,
    };
  },
});
