
import { computed, ComputedRef, defineComponent, Ref, ref } from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import { buttonTypes } from '@/types';

export default defineComponent({
  name: 'CButton',
  props: {
    is: {
      type: String,
      default: 'green',
      validator: (value: buttonTypes) => {
        return Object.values(buttonTypes).includes(value);
      },
    },
    buttonLabel: {
      type: String,
      default: 'default',
    },
    small: {
      type: Boolean,
      default: false,
    },
    halfSize: {
      type: Boolean,
      default: false,
    },
    onlyText: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const animate: Ref<boolean> = ref(false);
    const toggleAnimate = (): void => {
      animate.value = !animate.value;
    };

    const handleButtonClick = (event: MouseEvent): void => {
      toggleAnimate();
      emit('click', event);
    };

    const getActiveClass: ComputedRef<string> = computed(() => {
      switch (props.is) {
        case buttonTypes.GREEN: {
          return 'c-button--green';
        }
        case buttonTypes.WHITE: {
          return 'c-button--white';
        }
        case buttonTypes.LIGHT: {
          return 'c-button--light';
        }
        case buttonTypes.GRAY: {
          return 'c-button--gray';
        }
        case buttonTypes.GRAYDARK: {
          return 'c-button--gray--dark';
        }
        case buttonTypes.RED: {
          return 'c-button--red';
        }
        case buttonTypes.DASHED: {
          return 'c-button--dashed';
        }
        case buttonTypes.ONLYTEXT: {
          return 'c-button--only-text';
        }
        default:
          return '';
      }
    });

    const getSizingClass: ComputedRef<string> = computed(() =>
      props.small ? 'c-button--small' : ''
    );

    const getAnimationClass: ComputedRef<string> = computed(() =>
      animate.value ? 'c-button--animated' : ''
    );

    const getHalfSize: ComputedRef<string> = computed(() =>
      props.halfSize ? 'c-button--half-size' : ''
    );

    const getOnlyText: ComputedRef<string> = computed(() =>
      props.onlyText ? 'c-button--only-text' : ''
    );

    return {
      animate,
      toggleAnimate,
      handleButtonClick,
      getAnimationClass,
      getActiveClass,
      getSizingClass,
      getHalfSize,
      getOnlyText,
      useGlobalTranslations,
    };
  },
});
