import { reactive, ref, watch } from 'vue';
import { useGlobals } from '@/hooks/use-globals';
import { getUrl } from '@/service/service.helpers';
import axios, { AxiosPromise } from 'axios';
import { Notification } from '@/components/notification/notification';
import i18n from '@/i18n';
import { GLOBAL } from '@/helpers/variables';
import { name as vSetPassword } from '@/views/setPassword/set-password.route';
import { name as vLogin } from '@/views/login/login.route';

export const useSetPasswordForm = () => {
  const { store, router } = useGlobals();

  const resetForm = reactive({
    password: '',
    repeatPassword: '',
  });

  const disabledButton = ref(true);
  const token = ref('');

  watch(
    () => resetForm.password,
    (newValue) => {
      disabledButton.value = newValue !== resetForm.repeatPassword;
    }
  );

  watch(
    () => resetForm.repeatPassword,
    (newValue) => {
      disabledButton.value = newValue !== resetForm.password;
    }
  );

  const setToken = async (payload: string) => {
    try {
      await axios.get(getUrl('/users/set-password'), {
        params: {
          token: payload,
        },
      });
      token.value = payload;
    } catch (e) {
      Notification.error(
        i18n.global.t(`${GLOBAL}.errors.error`),
        i18n.global.t(`${vSetPassword}.form.errors.${e.data}`)
      );
    }
  };

  const onSubmit = async () => {
    if (resetForm.password === resetForm.repeatPassword) {
      if (resetForm.password.length < 6) {
        Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${vSetPassword}.form.errors.TOO_SHORT_PASSWORD`)
        );
        return;
      }
      try {
        await axios.put(getUrl(`/users/set-password?token=${token.value}`), {
          password: resetForm.password,
          repeatPassword: resetForm.repeatPassword,
        });
        Notification.success(
          i18n.global.t(`${GLOBAL}.success.success`),
          i18n.global.t(`${vSetPassword}.form.success.passwordHasBeenChanged`)
        );
        await router.push({ name: vLogin });
      } catch (e) {
        Notification.error(
          i18n.global.t(`${GLOBAL}.errors.error`),
          i18n.global.t(`${vSetPassword}.form.errors.TOKEN_NOT_VALID`)
        );
        throw Error(e);
      }
    }
  };

  return {
    disabledButton,
    resetForm,
    onSubmit,
    setToken,
  };
};
