
import { defineComponent }                        from 'vue';
import cButton                                  from '@/components/common/button/button.component.vue';
import { useGlobals }                             from '@/hooks/use-globals';
import { useGlobalTranslations, useTranslations } from '@/hooks/use-translations';
import { getViewStorePath }                       from '@/store/store.helpers';
import { name as vLogin }                         from '@/views/login/login.route';

export default defineComponent({
  name: 'CLoginFooterCookie',
  components: {
    cButton,
  },
  setup() {
    const { store } = useGlobals();

    const setCookiePolicy = (): void => {
      store.commit(`${getViewStorePath(vLogin)}/setCookiePolicy`, true);
    };

    return {
      useTranslations,
      useGlobalTranslations,
      setCookiePolicy,
    };
  },
});
