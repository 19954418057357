import { computed, ComputedRef }    from 'vue';
import { useGlobals }  from '@/hooks/use-globals';

interface UsePageSize {
  pageWidth: ComputedRef<number>;
  pageHeight: ComputedRef<number>;
}

interface UseDeviceSize {
  isMobile: ComputedRef<boolean>;
}

export const useDeviceSize = (): UseDeviceSize => {
  const { store } = useGlobals();
  const pageWidth = computed((): number => store.state.pageWidth);
  const mobileScreenWidthMax = 767;

  return {
    isMobile: computed((): boolean => pageWidth.value <= mobileScreenWidthMax),
  };
};

export const usePageSize = (): UsePageSize => {
  const { store } = useGlobals();

  return {
    pageWidth: computed((): number => store.state.pageWidth),
    pageHeight: computed((): number => store.state.pageHeight),
  };
};
